import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    fpf: {
                        address: 'Location',
                        noAddress: 'No Standort',
                        editFpF: 'Edit FpF',
                        createFpF: 'Create FpF',
                        hint: {
                            publicHint: 'Set the FPF to public or private',
                            sensorServiceIpHint: 'IP or URL of the FPF Backend',
                        },
                    },
                    header: {
                        title: 'FARMINSIGHT',
                        noFpfAvailable: 'No FPF available',
                        organizations: 'Organizations',
                        myOrganizations: 'My Organizations',
                        loginToManage: 'Login to manage organization',
                        loginToManageFpf: 'Login to manage facility',
                        loginToSee: 'Login to see your organizations',
                        login: 'Login',
                        logout: 'Logout',
                        search: 'Search FPFs...',
                        kick: 'Remove',
                        promote: 'Promote',
                        email: 'Email',
                        name: 'Name',
                        enterName: 'Enter Name',
                        address: 'Address',
                        enterAddress: 'Enter Address',
                        location: 'Location',
                        enterLocation: 'Enter Location',
                        role: 'Role',
                        organization: 'Organization',
                        searchUserProfile: 'Search User Profile',
                        noProfilesFound: 'No profiles found',
                        addUser: 'Add User',
                        noUserSelected: 'No user selected',
                        addSelectedUser: 'Add Selected User',
                        usersAdded: ' users have been added',
                        addFpf: 'Add FPF',
                        addOrganization: 'Create Organization',
                        createOrganization: 'Create new Organization',
                        public: 'Public',
                        private: 'Private',
                        yesDelete: 'Yes, delete',
                        cancel: 'Cancel',
                        confirmDialog: 'This action cannot be undone. Are you sure you want to delete?',
                        members: 'Members',
                        isActive: 'Active',
                        actions: 'Actions',
                        addSensor: 'Add Sensor',
                        dateError: 'End date should be after start date',
                        addHarvest: 'Add a Harvest',
                        totalHarvestAmount: 'Total Harvest',
                        organizationUpdated: 'Organization updated successfully!',
                        userAdded: 'User added',
                        status: 'Status',
                        table: {
                            name: 'Name',
                            planted: 'Planted on',
                            harvested: 'Harvested on',
                            notes: 'Notes',
                            date: 'Date',
                            amount: 'Amount (kg)',
                            details: 'Details for',
                        }
                    },
                    sensor: {
                        addSensor: 'Add Sensor',
                        editSensor: 'Edit Sensor',
                        noConfig: 'No Configuration available',
                        model: 'Model',
                        connectionType: 'Connection Type',
                        parameter: 'Parameter',
                        unit: 'Unit',
                        tags: 'Notes',
                        hint: {
                            nameHint: 'Name of the sensor',
                            locationHint: 'Location of the sensor',
                            intervalSecondsHint: 'Interval of capturing data',
                        },
                        title: 'Sensors',
                        noSensorsFound: 'No Sensor found',
                    },
                    userManagement: {
                        userKicked: 'User was kicked from Organization.',
                        userPromoted: 'User was promoted.'
                    },
                    growingCycleForm: {
                        hint: {
                            plantTypeHint: 'Type of plant',
                            startDateHint: 'Start date of the growing cycle',
                            endDateHint: 'End date of the growing cycle',
                            notesHint: 'Notes on the growing cycle',
                        },
                        plantTypeLabel: 'Plant Type',
                        plantTypePlaceholder: 'Enter Plant Type',
                        startDateLabel: 'Start Date',
                        startDatePlaceholder: 'Select Start Date',
                        endDateLabel: 'End Date',
                        endDatePlaceholder: 'Select End Date',
                        notesLabel: 'Notes',
                        notesPlaceholder: 'Enter Notes',
                        saveButton: 'Save',
                        successTitle: 'Success',
                        editSuccessMessage: 'Growing cycle updated successfully!',
                        createSuccessMessage: 'Growing cycle created successfully!',
                        errorTitle: 'Failed to save the growing cycle',
                    },
                    sensorList: {
                        name: 'Name',
                        location: 'Location',
                        unit: 'Unit',
                        modelNr: 'Model Nr',
                        intervalSeconds: 'Interval in Seconds',
                        isActive: 'Is Active?',
                        actions: 'Actions',
                    },
                    button: {
                        loginToManage: 'Login to manage organization',
                        create: 'Create',
                        add: 'Add',
                        update: 'Save changes'
                    },
                    label: {
                        organizationName: 'Organization Name',
                        setPublic: 'Set Public',
                        setCameraCarousel: 'Image or Livestream'
                    },
                    placeholder: {
                        enterOrganizationName: 'Enter organization name'
                    },
                    error: {
                        organizationNameTaken: 'Organization Name already taken',
                        fireFoxError: "Your browser is not fully supported. Some features, such as graphs and cameras, may not work as expected.\nPlease use a different browser for the best experience."

                    },
                    userprofile: {
                        notifications: {
                            success: {
                                title: 'Successfully updated',
                                message: 'Updated the Profile name successfully!',
                            },
                            error: {
                                title: 'Unable to update profile name',
                            }
                        },
                        enterName: 'Enter your display name',
                        saveChanges: 'Save Changes',

                    },
                    camera: {
                        active: 'Active',
                        inactive: 'Inactive',
                        cameras: 'Cameras',
                        hint: {
                                nameHint: 'Name of the camera',
                                locationHint: 'Location of the camera',
                                modelNrHint: 'Model number of the camera',
                                intervalSecondsHint: 'Interval of capturing images',
                                resolutionHint: 'Resolution of the camera',
                                livestreamUrlHint: 'URL for the livestream',
                                snapshotUrlHint: 'URL for the snapshot',
                            },
                        cameraList: 'Camera List',
                        noCamerasFound: 'No Camera found',
                        location: 'Location',
                        enterLocation: 'Enter Location',
                        modelNr: 'Model Nr.',
                        enterModelNr: 'Enter Model Nr.',
                        intervalSeconds: 'Interval in Seconds',
                        enterIntervalSeconds: 'Enter Interval in Seconds',
                        resolution: 'Resolution',
                        enterResolution: 'Enter Resolution',
                        livestreamUrl: 'Livestream URL',
                        enterLivestreamUrl: 'Enter Livestream URL',
                        snapshotUrl: 'Snapshot URL',
                        enterSnapshotUrl: 'Enter Snapshot URL',
                        addCamera: 'Add Camera',
                        editCamera: 'Edit Camera',
                        createCamera: 'Create Camera',
                    },
                    harvestEntityForm: {
                        hint: {
                            dateDescription: 'Date of the harvest',
                            amountDescription: 'Amount of the harvest',
                            notesDescription: 'Notes on the harvest',
                        },
                        addHarvest: 'Add Harvest Entity',
                        dateLabel: 'Date',
                        datePlaceholder: 'Select Date',
                        amountLabel: 'Amount (kg)',
                        amountPlaceholder: 'Enter Amount',
                        amountDescription: "Use '.' for decimals, not ','",
                        notesLabel: 'Notes',
                        notesPlaceholder: 'Enter Notes',
                        saveButton: 'Save',
                    }
                },
            },
            de: {
                translation: {
                    fpf: {
                        address: 'Standort',
                        noAddress: 'Kein Adresse',
                        editFpF: 'FpF bearbeiten',
                        createFpF: 'FpF erstellen',
                        hint: {
                            publicHint: 'FpF öffentlich oder privat einstellen.',
                            sensorServiceIpHint: 'IP oder URL des FPF-Backends',
                        },
                    },
                    header: {
                        title: 'FARMINSIGHT',
                        noFpfAvailable: 'Kein FPF verfügbar',
                        organizations: 'Organisationen',
                        myOrganizations: 'Meine Organisationen',
                        loginToManage: 'Einloggen, um Organisation zu verwalten',
                        loginToSee: 'Einloggen, um Ihre Organisationen zu sehen',
                        login: 'Einloggen',
                        logout: 'Abmelden',
                        search: 'FPFs durchsuchen...',
                        kick: 'Entfernen',
                        promote: 'Befördern',
                        email: 'E-Mail-Adresse',
                        name: 'Name',
                        enterName: 'Name eingeben',
                        address: 'Adresse',
                        enterAddress: 'Adresse eingeben',
                        location: 'Standort',
                        enterLocation: 'Standort eingeben',
                        role: 'Rolle',
                        organization: 'Organisation',
                        searchUserProfile: 'Nutzerprofil suchen',
                        addUser: 'Benutzer hinzufügen',
                        noProfilesFound: 'Keine Profile gefunden',
                        noUserSelected: 'Kein Benutzer ausgewählt',
                        addSelectedUser: 'Ausgewählten Benutzer hinzufügen',
                        usersAdded: ' Benutzer wurden hinzugefügt',
                        addFpf: 'FPF hinzufügen',
                        addOrganization: 'Organisation erstellen',
                        createOrganization: 'Neue Organisation erstellen',
                        public: 'Öffentlich',
                        private: 'Privat',
                        yesDelete: 'Ja, löschen',
                        cancel: 'Abbrechen',
                        confirmDialog: 'Diese Aktion kann nicht rückgängig gemacht werden. Sind Sie sicher, dass es gelöscht werden soll?',
                        members: 'Mitglieder',
                        isActive: 'Aktiv',
                        actions: 'Aktionen',
                        addSensor: 'Sensor hinzufügen',
                        dateError: 'Enddatum sollte nach dem Startdatum liegen',
                        addHarvest: 'Ernte hinzufügen',
                        totalHarvestAmount: 'Gesamternte',
                        organizationUpdated: 'Organisation erfolgreich aktualisiert!',
                        userAdded: 'Benutzer hinzugefügt',
                        status: 'Status',
                        table: {
                            name: 'Name',
                            planted: 'Gepflanzt am',
                            harvested: 'Geerntet am',
                            notes: 'Bemerkungen',
                            date: 'Datum',
                            amount: 'Menge (kg)',
                            details: 'Details für',
                        }
                    },
                    sensor: {
                        addSensor: 'Sensor hinzufügen',
                        editSensor: 'Sensor bearbeiten',
                        noConfig: 'Keine Konfiguration verfügbar',
                        model: 'Modell',
                        connectionType: 'Verbindungstyp',
                        parameter: 'Kennwert',
                        unit: 'Einheit',
                        tags: 'Notizen',
                        hint: {
                            nameHint: 'Name des Sensors',
                            locationHint: 'Standort des Sensors',
                            intervalSecondsHint: 'Intervall zum Erfassen von Daten',
                        },
                        title: 'Sensoren',
                        noSensorsFound: 'Kein Sensor gefunden',
                    },
                    userManagement: {
                        userKicked: 'Benutzer wurde aus der Organisation entfernt.',
                        userPromoted: 'Benutzer wurde befördert.'
                    },
                    growingCycleForm: {
                        hint: {
                            plantTypeHint: 'Pflanzentyp',
                            startDateHint: 'Startdatum des Wachstumszyklus',
                            endDateHint: 'Enddatum des Wachstumszyklus',
                            notesHint: 'Notizen zum Wachstumszyklus',
                        },
                        plantTypeLabel: 'Pflanzentyp',
                        plantTypePlaceholder: 'Pflanzentyp eingeben',
                        startDateLabel: 'Startdatum',
                        startDatePlaceholder: 'Startdatum auswählen',
                        endDateLabel: 'Enddatum',
                        endDatePlaceholder: 'Enddatum auswählen',
                        notesLabel: 'Notizen',
                        notesPlaceholder: 'Notizen eingeben',
                        saveButton: 'Speichern',
                        successTitle: 'Erfolg',
                        editSuccessMessage: 'Wachstumszyklus erfolgreich aktualisiert!',
                        createSuccessMessage: 'Wachstumszyklus erfolgreich erstellt!',
                        errorTitle: 'Speichern des Wachstumszyklus fehlgeschlagen',
                    },
                    sensorList: {
                        name: 'Name',
                        location: 'Standort',
                        unit: 'Einheit',
                        modelNr: 'Modell Nr.',
                        intervalSeconds: 'Intervall in Sekunden',
                        isActive: 'Ist aktiv?',
                        actions: 'Aktionen',
                    },
                    button: {
                        loginToManage: 'Einloggen, um Organisation zu verwalten',
                        create: 'Erstellen',
                        add: 'Hinzufügen',
                        update:'Änderungen speichern,'
                    },
                    label: {
                        organizationName: 'Organisationsname',
                        setPublic: 'Öffentlich einstellen',
                        setCameraCarousel: 'Bilder oder Livestream'
                    },
                    placeholder: {
                        enterOrganizationName: 'Geben Sie den Organisationsnamen ein'
                    },
                    error: {
                        organizationNameTaken: 'Organisationsname bereits vergeben',
                        fireFoxError: 'Ihr Browser wird nicht vollständig unterstützt. Einige Funktionen wie Grafiken und Kameras funktionieren möglicherweise nicht wie erwartet.\nBitte verwenden Sie einen anderen Browser für das beste Erlebnis.'
                    },
                    userprofile: {
                        notifications: {
                            success: {
                                title: 'Erfolgreich aktualisiert',
                                message: 'Profilname erfolgreich aktualisiert!',
                            },
                            error: {
                                title: 'Profilname konnte nicht aktualisiert werden',
                            }
                        },
                        enterName: 'Geben Sie Ihren Anzeigenamen ein',
                        saveChanges: 'Änderungen speichern',
                    },
                    camera: {
                        active: 'Aktiv',
                        inactive: 'Inaktiv',
                        cameras: 'Kameras',
                        hint: {
                                nameHint: 'Name der Kamera',
                                locationHint: 'Standort der Kamera',
                                modelNrHint: 'Modellnummer der Kamera',
                                intervalSecondsHint: 'Intervall zum Aufnehmen von Bildern',
                                resolutionHint: 'Auflösung der Kamera',
                                livestreamUrlHint: 'URL für den Livestream',
                                snapshotUrlHint: 'URL für den Schnappschuss',
                        },
                        cameraList: 'Kamera-Liste',
                        noCamerasFound: 'Keine Kamera gefunden',
                        location: 'Standort',
                        enterLocation: 'Standort eingeben',
                        modelNr: 'Modell Nr.',
                        enterModelNr: 'Modell Nr. eingeben',
                        intervalSeconds: 'Intervall in Sekunden',
                        enterIntervalSeconds: 'Intervall in Sekunden eingeben',
                        resolution: 'Auflösung',
                        enterResolution: 'Auflösung eingeben',
                        livestreamUrl: 'Livestream-URL',
                        enterLivestreamUrl: 'Livestream-URL eingeben',
                        snapshotUrl: 'Snapshot-URL',
                        enterSnapshotUrl: 'Snapshot-URL eingeben',
                        addCamera: 'Kamera hinzufügen',
                        editCamera: 'Kamera bearbeiten',
                        createCamera: 'Kamera erstellen',
                    },
                    harvestEntityForm: {
                        hint: {
                            dateDescription: 'Datum der Ernte',
                            amountDescription: 'Menge der Ernte',
                            notesDescription: 'Notizen zur Ernte',
                        },
                        addHarvest: 'Ernte hinzufügen',
                        dateLabel: 'Datum',
                        datePlaceholder: 'Datum auswählen',
                        amountLabel: 'Menge (kg)',
                        amountPlaceholder: 'Menge eingeben',
                        amountDescription: "Verwenden Sie '.' für Dezimalstellen, nicht ','",
                        notesLabel: 'Notizen',
                        notesPlaceholder: 'Notizen eingeben',
                        saveButton: 'Speichern',
                    }
                },
            },
            fr: {
                translation: {
                    fpf: {
                        address: 'Emplacement',
                        noAddress: 'Pas d’adresse',
                        editFpF: 'Modifier FpF' ,
                        createFpF: 'Créer FpF',
                        hint: {
                            publicHint: 'Définir le FPF comme public ou privé',
                            sensorServiceIpHint: 'IP ou URL du backend FPF',
                        },
                    },
                    header: {
                        title: 'FARMINSIGHT',
                        noFpfAvailable: 'Aucun FPF disponible',
                        organizations: 'Organisations',
                        myOrganizations: 'Mes organisations',
                        loginToManage: 'Se connecter pour gérer l’organisation',
                        loginToSee: 'Se connecter pour voir vos organisations',
                        login: 'Se connecter',
                        logout: 'Se déconnecter',
                        search: 'Rechercher des FPF...',
                        kick: 'Retirer',
                        promote: 'Promouvoir',
                        email: 'Adresse e-mail',
                        name: 'Nom',
                        address: 'Adresse',
                        enterAddress: 'Entrez l’adresse',
                        location: 'Emplacement',
                        enterLocation: 'Entrez l’emplacement',
                        enterName: 'Entrez le nom',
                        role: 'Rôle',
                        organization: 'Organisation',
                        searchUserProfile: 'Rechercher un profil utilisateur',
                        noProfilesFound: 'Aucun profil trouvé',
                        addUser: 'Ajouter un utilisateur',
                        noSelectedUser: 'Aucun utilisateur sélectionné',
                        addSelectedUser: 'Ajouter l’utilisateur sélectionné',
                        usersAdded: ' utilis ateurs ont été ajoutés',
                        addFpf: 'Ajouter un FPF',
                        addOrganization: 'Créer une organisation',
                        createOrganization: 'Créer une nouvelle organisation',
                        public: 'Public',
                        private: 'Privé',
                        yesDelete: 'Oui, supprimer',
                        cancel: 'Annuler',
                        confirmDialog: 'Cette action est irréversible. Êtes-vous sûr de vouloir supprimer ?',
                        members: 'Membres',
                        isActive: 'Actif',
                        actions: 'Actions',
                        addSensor: 'Ajouter un capteur',
                        dateError: 'La date de fin doit être postérieure à la date de début',
                        addHarvest: 'Ajouter une récolte',
                        totalHarvestAmount: 'Récolte totale',
                        organizationUpdated: 'Organisation mise à jour avec succès !',
                        userAdded: 'Utilisateur ajouté',
                        status: 'Statut',
                        table: {
                            name: 'Nom',
                            planted: 'Planté le',
                            harvested: 'Récolté le',
                            notes: 'Remarques',
                            details: 'Détails pour',
                            date: 'Date',
                            amount: 'Quantité (kg)',
                        }
                    },
                    sensor: {
                        addSensor: 'Ajouter un capteur',
                        editSensor: 'Modifier le capteur',
                        noConfig: 'Aucune configuration disponible',
                        model: 'Modèle',
                        connectionType: 'Type de connexion',
                        parameter: 'Paramètre',
                        unit: 'Unité',
                        tags: 'Remarques',
                        hint: {
                            nameHint: 'Nom du capteur',
                            locationHint: 'Emplacement du capteur',
                            intervalSecondsHint: 'Intervalle de capture des données',
                        },
                        title: 'Capteurs',
                        noSensorsFound: 'Aucun capteur trouvé',
                    },
                    userManagement: {
                        userKicked: 'L’utilisateur a été retiré de l’organisation.',
                        userPromoted: 'L’utilisateur a été promu.'
                    },
                    growingCycleForm: {
                        hint: {
                            plantTypeHint: 'Type de plante',
                            startDateHint: 'Date de début du cycle de croissance',
                            endDateHint: 'Date de fin du cycle de croissance',
                            notesHint: 'Notes sur le cycle de croissance',
                        },
                        plantTypeLabel: 'Type de plante',
                        plantTypePlaceholder: 'Entrez le type de plante',
                        startDateLabel: 'Date de début',
                        startDatePlaceholder: 'Sélectionnez la date de début',
                        endDateLabel: 'Date de fin',
                        endDatePlaceholder: 'Sélectionnez la date de fin',
                        notesLabel: 'Remarques',
                        notesPlaceholder: 'Entrez des remarques',
                        saveButton: 'Enregistrer',
                        successTitle: 'Succès',
                        editSuccessMessage: 'Cycle de croissance mis à jour avec succès !',
                        createSuccessMessage: 'Cycle de croissance créé avec succès !',
                        errorTitle: 'Impossible d’enregistrer le cycle de croissance',
                    },
                    sensorList: {
                        name: 'Nom',
                        location: 'Emplacement',
                        unit: 'Unité',
                        modelNr: 'Numéro de modèle',
                        intervalSeconds: 'Intervalle en secondes',
                        isActive: 'Est actif ?',
                        actions: 'Actions',
                    },
                    button: {
                        loginToManage: 'Se connecter pour gérer l’organisation',
                        create: 'Créer'
                    },
                    label: {
                        organizationName: 'Nom de l’organisation',
                        setPublic: 'Définir comme public',
                        setCameraCarousel: 'Imagee or live de stream'
                    },
                    placeholder: {
                        enterOrganizationName: 'Entrez le nom de l’organisation'
                    },
                    error: {
                        organizationNameTaken: 'Le nom de l’organisation est déjà pris',
                        fireFoxError: 'Votre navigateur n’est pas entièrement pris en charge. Certaines fonctionnalités, telles que les graphiques et les caméras, peuvent ne pas fonctionner comme prévu.\nVeuillez utiliser un autre navigateur pour une meilleure expérience.'
                    },
                    userprofile: {
                        notifications: {
                            success: {
                                title: 'Mise à jour réussie',
                                message: 'Nom du profil mis à jour avec succès !',
                            },
                            error: {
                                title: 'Impossible de mettre à jour le nom du profil',
                            }
                        },
                        enterName: 'Entrez votre nom d’affichage',
                        saveChanges: 'Enregistrer les modifications',
                    },
                    camera: {
                        active: 'Actif',
                        inactive: 'Inactif',
                        cameras: 'Caméras',
                        hint: {
                                nameHint: 'Nom de la caméra',
                                locationHint: 'Emplacement de la caméra',
                                modelNrHint: 'Numéro de modèle de la caméra',
                                intervalSecondsHint: 'Intervalle de capture des images',
                                resolutionHint: 'Résolution de la caméra',
                                livestreamUrlHint: 'URL du flux en direct',
                                snapshotUrlHint: 'URL de la capture instantanée',
                            },
                        cameraList: 'Liste des caméras',
                        noCamerasFound: 'Aucune caméra trouvée',
                        location: 'Emplacement',
                        enterLocation: 'Entrez l’emplacement',
                        modelNr: 'Numéro de modèle',
                        enterModelNr: 'Entrez le numéro de modèle',
                        intervalSeconds: 'Intervalle en secondes',
                        enterIntervalSeconds: 'Entrez l’intervalle en secondes',
                        resolution: 'Résolution',
                        enterResolution: 'Entrez la résolution',
                        livestreamUrl: 'URL du flux en direct',
                        enterLivestreamUrl: 'Entrez l’URL du flux en direct',
                        snapshotUrl: 'URL de capture instantanée',
                        enterSnapshotUrl: 'Entrez l’URL de capture instantanée',
                        addCamera: 'Ajouter une caméra',
                        editCamera: 'Modifier la caméra',
                        createCamera: 'Créer une caméra',
                    },
                    harvestEntityForm: {
                        hint: {
                            dateDescription: 'Date de la récolte',
                            amountDescription: 'Quantité de la récolte',
                            notesDescription: 'Remarques sur la récolte',
                        },
                        addHarvest: 'Ajouter une entité de récolte',
                        dateLabel: 'Date',
                        datePlaceholder: 'Sélectionnez la date',
                        amountLabel: 'Quantité (kg)',
                        amountPlaceholder: 'Entrez la quantité',
                        amountDescription: "Utilisez '.' pour les décimales, pas ','",
                        notesLabel: 'Remarques',
                        notesPlaceholder: 'Entrez des remarques',
                        saveButton: 'Enregistrer',
                    }
                },
            },
            it: {
                translation: {
                    fpf: {
                        address: 'Posizione',
                        noAddress: 'Nessuna posizione',
                        editFpF: 'Modifica FpF',
                        createFpF: 'Crea FpF',
                        hint: {
                            publicHint: 'Imposta il FPF su pubblico o privato',
                            sensorServiceIpHint: 'IP o URL del backend FPF',
                        },
                    },
                    header: {
                        title: 'FARMINSIGHT',
                        noFpfAvailable: 'Nessun FPF disponibile',
                        organizations: 'Organizzazioni',
                        myOrganizations: 'Le mie organizzazioni',
                        loginToManage: 'Accedi per gestire l’organizzazione',
                        loginToSee: 'Accedi per vedere le tue organizzazioni',
                        login: 'Accedi',
                        logout: 'Disconnettersi',
                        search: 'Cerca FPF...',
                        kick: 'Rimuovere',
                        promote: 'Promuovere',
                        email: 'Indirizzo email',
                        name: 'Nome',
                        address: 'Indirizzo',
                        enterAddress: 'Inserisci l’indirizzo',
                        location: 'Posizione',
                        enterLocation: 'Inserisci la posizione',
                        enterName: 'Inserisci il nome',
                        role: 'Ruolo',
                        organization: 'Organizzazione',
                        searchUserProfile: 'Cerca profilo utente',
                        noProfilesFound: 'Nessun profilo trovato',
                        addUser: 'Aggiungi utente',
                        noUserSelected: 'Nessun utente selezionato',
                        addSelectedUser: 'Aggiungi l’utente selezionato',
                        usersAdded: ' utenti sono stati aggiunti',
                        addFpf: 'Aggiungi FPF',
                        addOrganization: 'Crea organizzazione',
                        createOrganization: 'Crea una nuova organizzazione',
                        public: 'Pubblico',
                        private: 'Privato',
                        yesDelete: 'Sì, elimina',
                        cancel: 'Annulla',
                        confirmDialog: 'Questa azione non può essere annullata. Sei sicuro di voler eliminare?',
                        members: 'Membri',
                        isActive: 'Attivo',
                        actions: 'Azioni',
                        addSensor: 'Aggiungi sensore',
                        dateError: 'La data di fine deve essere successiva alla data di inizio',
                        addHarvest: 'Aggiungi un raccolto',
                        totalHarvestAmount: 'Raccolto totale',
                        organizationUpdated: 'Organizzazione aggiornata con successo!',
                        userAdded: 'Utente aggiunto',
                        status: 'Stato',
                        table: {
                            name: 'Nome',
                            planted: 'Pianta il',
                            harvested: 'Raccolto il',
                            notes: 'Annotazioni',
                            details: 'Dettagli per',
                            date: 'Data',
                            amount: 'Quantità (kg)',
                        }
                    },
                    sensor: {
                        addSensor: 'Aggiungi sensore',
                        editSensor: 'Modifica sensore',
                        noConfig: 'Nessuna configurazione disponibile',
                        model: 'Modello',
                        connectionType: 'Tipo di connessione',
                        parameter: 'Parametro',
                        unit: 'Unità',
                        tags: 'Note',
                        hint: {
                            nameHint: 'Nome del sensore',
                            locationHint: 'Posizione del sensore',
                            intervalSecondsHint: 'Intervallo di acquisizione dei dati',
                        },
                        title: 'Sensori',
                        noSensorsFound: 'Nessun sensore trovato',
                    },
                    userManagement: {
                        userKicked: 'L’utente è stato rimosso dall’organizzazione.',
                        userPromoted: 'L’utente è stato promosso.'
                    },
                    growingCycleForm: {
                        hint: {
                            plantTypeHint: 'Tipo di pianta',
                            startDateHint: 'Data di inizio del ciclo di crescita',
                            endDateHint: 'Data di fine del ciclo di crescita',
                            notesHint: 'Note sul ciclo di crescita',
                        },
                        plantTypeLabel: 'Tipo di pianta',
                        plantTypePlaceholder: 'Inserisci il tipo di pianta',
                        startDateLabel: 'Data di inizio',
                        startDatePlaceholder: 'Seleziona la data di inizio',
                        endDateLabel: 'Data di fine',
                        endDatePlaceholder: 'Seleziona la data di fine',
                        notesLabel: 'Note',
                        notesPlaceholder: 'Inserisci le note',
                        saveButton: 'Salva',
                        successTitle: 'Successo',
                        editSuccessMessage: 'Ciclo di crescita aggiornato con successo!',
                        createSuccessMessage: 'Ciclo di crescita creato con successo!',
                        errorTitle: 'Impossibile salvare il ciclo di crescita',
                    },
                    sensorList: {
                        name: 'Nome',
                        location: 'Posizione',
                        unit: 'Unità',
                        modelNr: 'Numero di modello',
                        intervalSeconds: 'Intervallo in secondi',
                        isActive: 'È attivo?',
                        actions: 'Azioni',
                    },
                    button: {
                        loginToManage: 'Accedi per gestire l’organizzazione',
                        create: 'Crea',
                        add: 'Aggiungi'
                    },
                    label: {
                        organizationName: 'Nome dell’organizzazione',
                        setPublic: 'Imposta come pubblico',
                        setCameraCarousel: 'Immagine o live stream'
                    },
                    placeholder: {
                        enterOrganizationName: 'Inserisci il nome dell’organizzazione'
                    },
                    error: {
                        organizationNameTaken: 'Il nome dell’organizzazione è già preso',
                        fireFoxError: 'Il tuo browser non è completamente supportato. Alcune funzionalità, come grafici e telecamere, potrebbero non funzionare come previsto.\nSi prega di utilizzare un browser diverso per un’esperienza ottimale.'
                    },
                    userprofile: {
                        notifications: {
                            success: {
                                title: 'Aggiornamento riuscito',
                                message: 'Nome del profilo aggiornato con successo!',
                            },
                            error: {
                                title: 'Impossibile aggiornare il nome del profilo',
                            }
                        },
                        enterName: 'Inserisci il tuo nome visualizzato',
                        saveChanges: 'Salva le modifiche',
                    },
                    camera: {
                        active: 'Attivo',
                        inactive: 'Inattivo',
                        cameras: 'Fotocamere',
                        hint: {
                                nameHint: 'Nome della fotocamera',
                                locationHint: 'Posizione della fotocamera',
                                modelNrHint: 'Numero di modello della fotocamera',
                                intervalSecondsHint: 'Intervallo di cattura delle immagini',
                                resolutionHint: 'Risoluzione della fotocamera',
                                livestreamUrlHint: 'URL per il livestream',
                                snapshotUrlHint: 'URL per lo snapshot',
                            },
                        cameraList: 'Elenco delle fotocamere',
                        noCamerasFound: 'Nessuna fotocamera trovata',
                        location: 'Posizione',
                        enterLocation: 'Inserisci la posizione',
                        modelNr: 'Numero di modello',
                        enterModelNr: 'Inserisci il numero di modello',
                        intervalSeconds: 'Intervallo in secondi',
                        enterIntervalSeconds: 'Inserisci l’intervallo in secondi',
                        resolution: 'Risoluzione',
                        enterResolution: 'Inserisci la risoluzione',
                        livestreamUrl: 'URL del livestream',
                        enterLivestreamUrl: 'Inserisci l’URL del livestream',
                        snapshotUrl: 'URL dello snapshot',
                        enterSnapshotUrl: 'Inserisci l’URL dello snapshot',
                        addCamera: 'Aggiungi fotocamera',
                        editCamera: 'Modifica fotocamera',
                        createCamera: 'Crea fotocamera',
                    },
                    harvestEntityForm: {
                        hint: {
                            dateDescription: 'Data del raccolto',
                            amountDescription: 'Quantità del raccolto',
                            notesDescription: 'Note sul raccolto',
                        },
                        addHarvest: 'Aggiungi entità di raccolto',
                        dateLabel: 'Data',
                        datePlaceholder: 'Seleziona la data',
                        amountLabel: 'Quantità (kg)',
                        amountPlaceholder: 'Inserisci la quantità',
                        amountDescription: "Usa '.' per i decimali, non ','",
                        notesLabel: 'Note',
                        notesPlaceholder: 'Inserisci le note',
                        saveButton: 'Salva',
                    }
                },
            },
            zh: {
                translation: {
                    fpf: {
                        address: '位置',
                        noAddress: '没有位置',
                        editFpF: '編輯 FpF',
                        createFpF: '创建 FpF',
                        hint: {
                            publicHint: '将 FPF 设置为公共或私有',
                            sensorServiceIpHint: 'FPF 后端的 IP 或 URL',
                        },
                    },
                    header: {
                        title: 'FARMINSIGHT',
                        noFpfAvailable: '没有可用的 FPF',
                        organizations: '组织',
                        myOrganizations: '我的组织',
                        loginToManage: '登录以管理组织',
                        loginToSee: '登录查看您的组织',
                        login: '登录',
                        logout: '退出登录',
                        search: '搜索 FPF...',
                        kick: '移除',
                        promote: '提升',
                        email: '电子邮箱',
                        name: '名称',
                        address: '地址',
                        enterAddress: '输入地址',
                        location: '位置',
                        enterLocation: '输入位置',
                        enterName: '输入名称',
                        role: '角色',
                        organization: '组织',
                        searchUserProfile: '搜索用户资料',
                        noProfilesFound: '未找到任何资料',
                        addUser: '添加用户',
                        noUserSelected: '未选择用户',
                        addSelectedUser: '添加选定用户',
                        usersAdded: ' 位用户已添加',
                        addFpf: '添加 FPF',
                        addOrganization: '创建组织',
                        createOrganization: '创建新组织',
                        public: '公开',
                        private: '私人',
                        yesDelete: '是的，删除',
                        cancel: '取消',
                        confirmDialog: '此操作无法撤销。您确定要删除吗？',
                        members: '成员',
                        isActive: '活跃',
                        actions: '操作',
                        addSensor: '添加传感器',
                        dateError: '结束日期应在开始日期之后',
                        addHarvest: '添加收获',
                        totalHarvestAmount: '总收获量',
                        organizationUpdated: '组织已成功更新！',
                        userAdded: '用户已添加',
                        status: '状态',
                        table: {
                            name: '名称',
                            planted: '种植时间',
                            harvested: '收获时间',
                            notes: '备注',
                            details: '详细信息',
                            date: '日期',
                            amount: '数量（千克）',
                        }
                    },
                    sensor: {
                        addSensor: '添加传感器',
                        editSensor: '编辑传感器',
                        noConfig: '没有可用的配置',
                        model: '型号',
                        connectionType: '连接类型',
                        parameter: '参数',
                        unit: '单位',
                        tags: '备注',
                        hint: {
                            nameHint: '传感器名称',
                            locationHint: '传感器位置',
                            intervalSecondsHint: '数据捕获间隔',
                        },
                        title: '传感器',
                        noSensorsFound: '未找到传感器',
                    },
                    userManagement: {
                        userKicked: '用户已从组织中移除。',
                        userPromoted: '用户已晋升。'
                    },
                    growingCycleForm: {
                        hint: {
                            plantTypeHint: '植物类型',
                            startDateHint: '生长周期开始日期',
                            endDateHint: '生长周期结束日期',
                            notesHint: '生长周期备注',
                        },
                        plantTypeLabel: '植物类型',
                        plantTypePlaceholder: '输入植物类型',
                        startDateLabel: '开始日期',
                        startDatePlaceholder: '选择开始日期',
                        endDateLabel: '结束日期',
                        endDatePlaceholder: '选择结束日期',
                        notesLabel: '备注',
                        notesPlaceholder: '输入备注',
                        saveButton: '保存',
                        successTitle: '成功',
                        editSuccessMessage: '成功更新了生长周期！',
                        createSuccessMessage: '成功创建了生长周期！',
                        errorTitle: '无法保存生长周期',
                    },
                    sensorList: {
                        name: '名称',
                        location: '位置',
                        unit: '单位',
                        modelNr: '型号',
                        intervalSeconds: '间隔时间（秒）',
                        isActive: '是否活跃？',
                        actions: '操作',
                    },
                    button: {
                        loginToManage: '登录以管理组织',
                        create: '创建',
                        add: '添加'
                    },
                    label: {
                        organizationName: '组织名称',
                        setPublic: '设置公开',
                        setCameraCarousel: '图片或直播流'
                    },
                    placeholder: {
                        enterOrganizationName: '输入组织名称'
                    },
                    error: {
                        organizationNameTaken: '组织名称已被占用',
                        fireFoxError: '您的浏览器不完全支持。某些功能，如图表和摄像头，可能无法按预期工作。\n请使用其他浏览器以获得最佳体验。'
                    },
                    userprofile: {
                        notifications: {
                            success: {
                                title: '更新成功',
                                message: '成功更新了个人资料名称！',
                            },
                            error: {
                                title: '无法更新个人资料名称',
                            }
                        },
                        enterName: '输入您的显示名称',
                        saveChanges: '保存更改',
                    },
                    camera: {
                        active: '活跃',
                        inactive: '不活跃',
                        cameras: '摄像头',
                        hint: {
                                nameHint: '摄像头名称',
                                locationHint: '摄像头位置',
                                modelNrHint: '摄像头型号',
                                intervalSecondsHint: '图像捕获间隔',
                                resolutionHint: '摄像头分辨率',
                                livestreamUrlHint: '直播流 URL',
                                snapshotUrlHint: '快照 URL',
                            },
                        cameraList: '摄像头列表',
                        noCamerasFound: '未找到摄像头',
                        location: '位置',
                        enterLocation: '输入位置',
                        modelNr: '型号',
                        enterModelNr: '输入型号',
                        intervalSeconds: '间隔时间（秒）',
                        enterIntervalSeconds: '输入间隔时间（秒）',
                        resolution: '分辨率',
                        enterResolution: '输入分辨率',
                        livestreamUrl: '直播流 URL',
                        enterLivestreamUrl: '输入直播流 URL',
                        snapshotUrl: '快照 URL',
                        enterSnapshotUrl: '输入快照 URL',
                        addCamera: '添加摄像头',
                        editCamera: '编辑摄像头',
                        createCamera: '创建摄像头',
                    },
                    harvestEntityForm: {
                        hint: {
                            dateDescription: '收获日期',
                            amountDescription: '收获数量',
                            notesDescription: '收获备注',
                        },
                        addHarvest: '添加收获实体',
                        dateLabel: '日期',
                        datePlaceholder: '选择日期',
                        amountLabel: '数量（千克）',
                        amountPlaceholder: '输入数量',
                        amountDescription: "使用 '.' 表示小数，不要使用 ','",
                        notesLabel: '备注',
                        notesPlaceholder: '输入备注',
                        saveButton: '保存',
                    }
                },
            },
            ru: {
                translation: {
                    fpf: {
                        address: 'Местоположение',
                        noAddress: 'Нет адреса',
                        editFpF: 'редактировать FpF',
                        createFpF: 'Создать FpF',
                        hint: {
                            publicHint: 'Установить FPF как общедоступный или частный',
                            sensorServiceIpHint: 'IP или URL FPF-бэкенда',
                        },
                    },
                    header: {
                        title: 'FARMINSIGHT',
                        noFpfAvailable: 'Нет доступных FPF',
                        organizations: 'Организации',
                        myOrganizations: 'Мои организации',
                        loginToManage: 'Войти для управления организацией',
                        loginToSee: 'Войдите, чтобы увидеть ваши организации',
                        login: 'Войти',
                        logout: 'Выйти',
                        search: 'Искать FPF...',
                        kick: 'Удалить',
                        promote: 'Повысить',
                        email: 'Электронная почта',
                        name: 'Имя',
                        address: 'Адрес',
                        enterAddress: 'Введите адрес',
                        enterName: 'Введите имя',
                        location: 'Местоположение',
                        enterLocation: 'Введите местоположение',
                        role: 'Роль',
                        organization: 'Организация',
                        searchUserProfile: 'Поиск профиля пользователя',
                        noProfilesFound: 'Профили не найдены',
                        addUser: 'Добавить пользователя',
                        noUserSelected: 'Пользователь не выбран',
                        addSelectedUser: 'Добавить выбранного пользователя',
                        usersAdded: ' пользователей было добавлено',
                        addFpf: 'Добавить FPF',
                        addOrganization: 'Создать организацию',
                        createOrganization: 'Создать новую организацию',
                        public: 'Общедоступный',
                        private: 'Приватный',
                        yesDelete: 'Да, удалить',
                        cancel: 'Отмена',
                        confirmDialog: 'Это действие нельзя отменить. Вы уверены, что хотите удалить?',
                        members: 'Участники',
                        isActive: 'Активный',
                        actions: 'Действия',
                        addSensor: 'Добавить датчик',
                        dateError: 'Дата окончания должна быть после даты начала',
                        addHarvest: 'Добавить урожай',
                        totalHarvestAmount: 'Общий урожай',
                        organizationUpdated: 'Организация успешно обновлена!',
                        userAdded: 'Пользователь добавлен',
                        status: 'Статус',
                        table: {
                            name: 'Имя',
                            planted: 'Дата посадки',
                            harvested: 'Дата сбора',
                            notes: 'Примечания',
                            details: 'Подробности для',
                            date: 'Дата',
                            amount: 'Количество (кг)',
                        }
                    },
                    sensor: {
                        addSensor: 'Добавить датчик',
                        editSensor: 'Редактировать датчик',
                        noConfig: 'Нет доступной конфигурации',
                        model: 'Модель',
                        connectionType: 'Тип соединения',
                        parameter: 'Параметр',
                        unit: 'Единица',
                        tags: 'Теги',
                        hint: {
                            nameHint: 'Имя датчика',
                            locationHint: 'Местоположение датчика',
                            intervalSecondsHint: 'Интервал сбора данных',
                        },
                        title: 'Датчики',
                        noSensorsFound: 'Датчики не найдены',
                    },
                    userManagement: {
                        userKicked: 'Пользоват ель был удален из организации.',
                        userPromoted: 'Пользователь был повышен.'
                    },
                    growingCycleForm: {
                        hint: {
                            plantTypeHint: 'Тип растения',
                            startDateHint: 'Дата начала цикла роста',
                            endDateHint: 'Дата окончания цикла роста',
                            notesHint: 'Примечания к циклу роста',
                        },
                        plantTypeLabel: 'Тип растения',
                        plantTypePlaceholder: 'Введите тип растения',
                        startDateLabel: 'Дата начала',
                        startDatePlaceholder: 'Выберите дату начала',
                        endDateLabel: 'Дата окончания',
                        endDatePlaceholder: 'Выберите дату окончания',
                        notesLabel: 'Примечания',
                        notesPlaceholder: 'Введите примечания',
                        saveButton: 'Сохранить',
                        successTitle: 'Успешно',
                        editSuccessMessage: 'Цикл роста успешно обновлен!',
                        createSuccessMessage: 'Цикл роста успешно создан!',
                        errorTitle: 'Не удалось сохранить цикл роста',
                    },
                    sensorList: {
                        name: 'Имя',
                        location: 'Местопол',
                        unit: 'Единица',
                        modelNr: 'Номер модели',
                        intervalSeconds: 'Интервал в секундах',
                        isActive: 'Активен?',
                        actions: 'Действия',
                    },
                    button: {
                        loginToManage: 'Войти для управления организацией',
                        create: 'Создать',
                        add: 'Добавить'
                    },
                    label: {
                        organizationName: 'Название организации',
                        setPublic: 'Установить как публичное',
                        setCameraCarousel: 'Изображение или прямой эфир'
                    },
                    placeholder: {
                        enterOrganizationName: 'Введите название организации'
                    },
                    error: {
                        organizationNameTaken: 'Название организации уже занято',
                        fireFoxError: 'Ваш браузер не полностью поддерживается. Некоторые функции, такие как графики и камеры, могут не работать должным образом.\nПожалуйста, используйте другой браузер для лучшего опыта.'
                    },
                    userprofile: {
                        notifications: {
                            success: {
                                title: 'Успешно обновлено',
                                message: 'Имя профиля успешно обновлено!',
                            },
                            error: {
                                title: 'Не удалось обновить имя профиля',
                            }
                        },
                        enterName: 'Введите ваше отображаемое имя',
                        saveChanges: 'Сохранить изменения',
                    },
                    camera: {
                        active: 'Активный',
                        inactive: 'Неактивный',
                        cameras: 'Камеры',
                        hint: {
                                nameHint: 'Название камеры',
                                locationHint: 'Местоположение камеры',
                                modelNrHint: 'Номер модели камеры',
                                intervalSecondsHint: 'Интервал захвата изображений',
                                resolutionHint: 'Разрешение камеры',
                                livestreamUrlHint: 'URL прямой трансляции',
                                snapshotUrlHint: 'URL снимка',
                            },
                        cameraList: 'Список камер',
                        noCamerasFound: 'Камеры не найдены',
                        location: 'Местоположение',
                        enterLocation: 'Введите местоположение',
                        modelNr: 'Номер модели',
                        enterModelNr: 'Введите номер модели',
                        intervalSeconds: 'Интервал (в секундах)',
                        enterIntervalSeconds: 'Введите интервал (в секундах)',
                        resolution: 'Разрешение',
                        enterResolution: 'Введите разрешение',
                        livestreamUrl: 'URL прямой трансляции',
                        enterLivestreamUrl: 'Введите URL прямой трансляции',
                        snapshotUrl: 'URL снимка',
                        enterSnapshotUrl: 'Введите URL снимка',
                        addCamera: 'Добавить камеру',
                        editCamera: 'Редактировать камеру',
                        createCamera: 'Создать камеру',
                    },
                    harvestEntityForm: {
                        hint: {
                            dateDescription: 'Дата урожая',
                            amountDescription: 'Количество урожая',
                            notesDescription: 'Примечания к урожаю',
                        },
                        addHarvest: 'Добавить урожай',
                        dateLabel: 'Дата',
                        datePlaceholder: 'Выберите дату',
                        amountLabel: 'Количество (кг)',
                        amountPlaceholder: 'Введите количество',
                        amountDescription: "Используйте '.' для десятичных, не ','",
                        notesLabel: 'Примечания',
                        notesPlaceholder: 'Введите примечания',
                        saveButton: 'Сохранить',
                    }
                },
            },
        },
        lng: navigator.language.split('-')[0],
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;